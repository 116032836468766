<template>
  <va-navbar color="primary" shape class="">
    <template #left>
      <va-navbar-item></va-navbar-item>
    </template>
    <template #center>
      <va-navbar-item class="display-3" style="color: white"
        >Concept analytics</va-navbar-item
      >
    </template>
    <template #right>
      <va-navbar-item></va-navbar-item>
    </template>
  </va-navbar>
  <Suspense>
    <ChartsPinia />
    <!-- <Charts /> -->
  </Suspense>
</template>

<script>
// import Charts from "./components/Charts.vue";
import ChartsPinia from "./components/Charts_pinia.vue";
// import { useStore } from "vuex";
import { useLineItemStore } from "./stores/lineitems";

export default {
  name: "App",
  components: {
    // Charts,
    ChartsPinia,
  },

  setup() {
    const lineitemStore = useLineItemStore();

    // const store = useStore();

    lineitemStore.populateFromIndexedDB();
  },
};
</script>

<style>
#app {
  font-family: "Merriweather", serif;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
