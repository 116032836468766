<template>
  <v-chart class="chart"  :option="configObject" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import { TreemapChart } from "echarts/charts";

import { TitleComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { defineComponent } from "vue";

use([CanvasRenderer, TreemapChart, TitleComponent]);

export default defineComponent({
  name: "TreeChartComponent",
  components: { VChart },
  provide: {
    [THEME_KEY]: "dark",
  },
  props: { configObject: { type: Object } },
});
</script>

<style scoped></style>
