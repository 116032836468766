import { defineStore } from 'pinia'

export const useAudienceStore = defineStore('audience', {
    state: () => {
        return {
            audienceOptions: [
                "quintesseBs",
                "quintesseC",
                "cc_iab_class_id",
                "carbon_segment",
              ]
        }
    },
    actions: {


    },
    getters: {

    }
})