<template>
  <v-chart class="chart" :option="configObject" />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { defineComponent } from "vue";

use([
  CanvasRenderer,
  BarChart,

  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  name: "RaceChartComponent",
  components: { VChart },
  provide: {
    [THEME_KEY]: "dark",
  },
  props: { configObject: { type: Object } },

});
</script>

<style scoped>

</style>
