import { createApp } from 'vue'
import App from './App.vue'
import { VuesticPlugin } from 'vuestic-ui'
import 'vuestic-ui/dist/vuestic-ui.css'
import { store } from './store/store'
import { createPinia } from 'pinia'

createApp(App)
    .use(VuesticPlugin)
    .use(createPinia())
    .use(store)
    .mount('#app')
// app.use(VuesticPlugin)