import { APISettings } from './config.js';
import { formatToLocalTime } from "../computeFunctions/computeCharts";
const fetch = require('node-fetch')

const getPropIndex = (props, name) => props.indexOf(
    props.find((e) => e.Name === name)
);

const getLatestEvents = async ({ timeAgo, after, sitesFilter, lineItemFilter }) => {
    let data = await fetch(`${APISettings.baseURL}/latest/${after}/${timeAgo}`, {
        method: 'post',
        headers: APISettings.headers,
        body: JSON.stringify({ sitesFilter, lineItemFilter })
    })

    let json = await data.json();
    // console.log(json);
    let allrows = json.map(e => e.Rows)
    let { ColumnInfo } = json[0];
    let Rows = allrows.flat(Infinity)
    // console.log(Rows, ColumnInfo);

    let lineItemIndex = getPropIndex(ColumnInfo, "lineItemId")

    let timeIndex = getPropIndex(ColumnInfo, "time")
    let siteIndex = getPropIndex(ColumnInfo, "hostname")
    let adunitCodeIndex = getPropIndex(ColumnInfo, "adunitCode")

    let sortedRows = Rows.sort(
        (a, b) =>
            new Date(a.Data[timeIndex].ScalarValue) -
            new Date(b.Data[timeIndex].ScalarValue)
    ) // importing for showing the events
        .map(e => {
            e.Data[timeIndex].ScalarValue = formatToLocalTime(e.Data[timeIndex].ScalarValue)
            return e

        })
    return {
        timeSortedData: sortedRows,
        siteIndexCalced: siteIndex,
        lineItemIndexCalced: lineItemIndex,
        timeIndex,
        adunitCodeIndex,
        // quintesseIndex,
        columnInfo: ColumnInfo
    }
}


const getLineitems = async ({ lineitemIds }) => {


    let data = await fetch(`${APISettings.baseURL}/lineitems`, {
        method: 'post',
        headers: APISettings.headers,
        body: JSON.stringify({ lineitemIds })
    })
    let json = await data.json();
    return json
}
const getSeedData = async () => {
    let data = await fetch(`${APISettings.baseURL}/seeddata`, {
        method: 'get',
        headers: APISettings.headers,
    })
    let json = await data.json();
    return json
}
export { getLatestEvents, getLineitems, getSeedData }
