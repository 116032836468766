import { defineStore } from 'pinia'
import { set, keys, getAll } from "../helpers/idb";
import { getLineitems } from "../api/api";

export const useLineItemStore = defineStore('lineitem', {
    state: () => {
        return {
            lineItems: [],
            lineitemsToGet: []
        }
    },
    actions: {
        startLineItemLoop() {
            let that = this
            setTimeout(async function () {

                if (that.lineitemsToGet.length > 0) {
                    let newlineitems = await getLineitems({
                        lineitemIds: that.lineitemsToGet,
                    });
                    newlineitems.GAMIds.forEach((lineitem) => {
                        that.insertLineItem({
                            label: lineitem.name,
                            value: lineitem.id,
                        });
                    });
                    that.removeTranslatedItems({ newlineitems });
                }
                that.startLineItemLoop();
            }, 3000);
        },
        async populateFromIndexedDB() {
            console.time('restoreCache')
            let [keyss, values] = await Promise.all([keys(), getAll()])
            this.lineItems =  keyss.map((key, index) => ({ value: key, label: values[index] }))
            console.timeEnd('restoreCache')
        },
        insertLineItem(lineitem) {
            if (!this.lineItems.find(e => e.value === lineitem.value)) {
                set(lineitem.value, lineitem.label)
                this.lineItems.push(lineitem)
            }
        },
        addLineItemToGet(lineitem) {
            this.lineitemsToGet.push(lineitem)
        },
        removeTranslatedItems({ newlineitems }) {
            this.lineitemsToGet = this.lineitemsToGet.filter(
                (id) => !newlineitems.GAMIds.map((e) => e.id).includes(id)
            );
        }
    },
    getters: {

    }
})