import { defineStore } from 'pinia'
import { getSeedData } from "../api/api";

export const userSeedStore = defineStore('seed', {
    state: () => {
        return {
            sites: [],
            keyValues: []
        }
    },
    actions: {
        async getSeedData() {
            let [sites, keyValue] = await getSeedData();

            this.keyValues = keyValue.customKeyVals
            this.sites = sites.hostnames
        },
        addSite(site) {
            if (!this.sites.includes(site)) {
                this.sites.push(site)
            }
        }

    },
    getters: {

    }
})