import { defineStore } from 'pinia'
import { getLatestEvents } from "../api/api";
import { groups } from "../helpers/chartHelper";
import computingFunction from "../computeFunctions/computeCharts";

export const userEventsStore = defineStore('event', {
    state: () => {
        return {
            events: [],
            ColumnInfo: [],
            lineItemIndex: 0,
            siteIndex: 0,
            adunitIndex: 0,
            secondCountEvents: [],
            mainDrawingEvents: [],
            timer: null
        }
    },
    actions: {
        clearAllEventsAndStop() {
            clearTimeout(this.timer)
            this.secondCountEvents = []
            this.mainDrawingEvents = []
            this.events = []
        },
        async getEvents({ after = "first", timeAgo, sitesFilter, lineItemFilter }) {
            try {
                let {
                    timeSortedData,
                    lineItemIndexCalced,
                    adunitCodeIndex,
                    timeIndex,
                    siteIndexCalced,
                    columnInfo,
                } = await getLatestEvents({ timeAgo, after, sitesFilter, lineItemFilter });
                this.ColumnInfo = columnInfo;
                this.lineItemIndex = lineItemIndexCalced;
                this.siteIndex = siteIndexCalced;
                this.adunitIndex = adunitCodeIndex;
                let grouped = groups({ arr: timeSortedData, timeIndex });
                let groupedKeys = Object.keys(grouped);

                let last = groupedKeys[groupedKeys.length - 1];
                let nextEventTimesAfter = computingFunction.formatToAWSTimestream(last);
                console.log('nextEventTimesAfter', nextEventTimesAfter);

                groupedKeys.forEach((key) => {
                    let eventArray = grouped[key]

                    if (eventArray.length > 0) {
                        let dateOfGroup = eventArray[0].Data[timeIndex].ScalarValue;
                        let insertionNumber = eventArray.length;
                        let pushRow = [dateOfGroup, insertionNumber];
                        this.secondCountEvents.push(pushRow);
                    }
                    this.mainDrawingEvents.push(eventArray);


                    eventArray.forEach((event) => {
                        this.events.push(event);
                    });
                });
                let that = this
                that.timer = setTimeout(function () {
                    that.getEvents({ after: nextEventTimesAfter, sitesFilter, lineItemFilter });
                }, 30 * 1000);
            } catch (e) {
                console.log("e", e);
            }
        },


    }

})