
let getTitle = msg => ({
    text: msg,
    left: "center",
    padding: 25,
    textStyle: {
        fontFamily: 'Poppins'
    }

})



const areaChartOption = {
    tooltip: {
        trigger: 'axis',
        position: function (pt) {
            return [pt[0], '10%'];
        }
    },

    title: getTitle("Total event data count"),
    // toolbox: {
    //     feature: {
    //         dataZoom: {
    //             yAxisIndex: 'none'
    //         },
    // restore: {},
    // saveAsImage: {}
    //     }
    // },
    // dataZoom: [
    //     {
    //         show: true,
    //         start: 0,
    //         end: 100
    //     },
    //     {
    //         type: 'inside',
    //         start: 0,
    //         end: 100,

    //     },

    // ],
    xAxis: {
        type: 'time',
        splitNumber: 0,

        axisLabel: {
            rotate: 10
        },
        // maxInterval: 10000,

        boundaryGap: false,
        axisTick: {

            length: 6,
            lineStyle: {
                type: 'dashed'
            }
        },

    },
    yAxis: {
        type: 'value',
        boundaryGap: [0, '100%']
    },

    series: [
        {
            name: 'Event count',
            type: 'bar',
            // sampling: 'lttb',
            itemStyle: {
                color: 'rgb(255, 70, 131)',
                borderRadius: [5, 5, 0, 0]
            },

            // type: 'line',
            smooth: true,
            symbol: 'none',
            areaStyle: {},
            data: []
        }
    ]
};




const hours = [
    '01', '02', '03', '04', '05', '06', '07',
    '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23'
];
// prettier-ignore
const lineItems = [

];
// prettier-ignore
const data = [
    [0, 0, 5], [0, 1, 1], [0, 2, 0], [0, 3, 0], [0, 4, 0], [0, 5, 0], [0, 6, 0], [0, 7, 0], [0, 8, 0], [0, 9, 0], [0, 10, 0], [0, 11, 2], [0, 12, 4], [0, 13, 1], [0, 14, 1], [0, 15, 3], [0, 16, 4], [0, 17, 6], [0, 18, 4], [0, 19, 4], [0, 20, 3], [0, 21, 3], [0, 22, 2], [0, 23, 5], [1, 0, 7], [1, 1, 0], [1, 2, 0], [1, 3, 0], [1, 4, 0], [1, 5, 0], [1, 6, 0], [1, 7, 0], [1, 8, 0], [1, 9, 0], [1, 10, 5], [1, 11, 2], [1, 12, 2], [1, 13, 6], [1, 14, 9], [1, 15, 11], [1, 16, 6], [1, 17, 7], [1, 18, 8], [1, 19, 12], [1, 20, 5], [1, 21, 5], [1, 22, 7], [1, 23, 2], [2, 0, 1], [2, 1, 1], [2, 2, 0], [2, 3, 0], [2, 4, 0], [2, 5, 0], [2, 6, 0], [2, 7, 0], [2, 8, 0], [2, 9, 0], [2, 10, 3], [2, 11, 2], [2, 12, 1], [2, 13, 9], [2, 14, 8], [2, 15, 10], [2, 16, 6], [2, 17, 5], [2, 18, 5], [2, 19, 5], [2, 20, 7], [2, 21, 4], [2, 22, 2], [2, 23, 4], [3, 0, 7], [3, 1, 3], [3, 2, 0], [3, 3, 0], [3, 4, 0], [3, 5, 0], [3, 6, 0], [3, 7, 0], [3, 8, 1], [3, 9, 0], [3, 10, 5], [3, 11, 4], [3, 12, 7], [3, 13, 14], [3, 14, 13], [3, 15, 12], [3, 16, 9], [3, 17, 5], [3, 18, 5], [3, 19, 10], [3, 20, 6], [3, 21, 4], [3, 22, 4], [3, 23, 1], [4, 0, 1], [4, 1, 3], [4, 2, 0], [4, 3, 0], [4, 4, 0], [4, 5, 1], [4, 6, 0], [4, 7, 0], [4, 8, 0], [4, 9, 2], [4, 10, 4], [4, 11, 4], [4, 12, 2], [4, 13, 4], [4, 14, 4], [4, 15, 14], [4, 16, 12], [4, 17, 1], [4, 18, 8], [4, 19, 5], [4, 20, 3], [4, 21, 7], [4, 22, 3], [4, 23, 0], [5, 0, 2], [5, 1, 1], [5, 2, 0], [5, 3, 3], [5, 4, 0], [5, 5, 0], [5, 6, 0], [5, 7, 0], [5, 8, 2], [5, 9, 0], [5, 10, 4], [5, 11, 1], [5, 12, 5], [5, 13, 10], [5, 14, 5], [5, 15, 7], [5, 16, 11], [5, 17, 6], [5, 18, 0], [5, 19, 5], [5, 20, 3], [5, 21, 4], [5, 22, 2], [5, 23, 0], [6, 0, 1], [6, 1, 0], [6, 2, 0], [6, 3, 0], [6, 4, 0], [6, 5, 0], [6, 6, 0], [6, 7, 0], [6, 8, 0], [6, 9, 0], [6, 10, 1], [6, 11, 0], [6, 12, 2], [6, 13, 1], [6, 14, 3], [6, 15, 4], [6, 16, 0], [6, 17, 0], [6, 18, 0], [6, 19, 0], [6, 20, 1], [6, 21, 2], [6, 22, 2], [6, 23, 6]]
    .map(function (item) {
        return [item[1], item[0], item[2] || '-'];
    });
const heatMapOptions = {
    tooltip: {
        position: 'top'
    },
    grid: {
        height: '50%',
        top: '10%'
    },
    xAxis: {
        type: 'category',
        data: hours,
        splitArea: {
            show: true
        }
    },
    yAxis: {
        type: 'category',
        data: lineItems,
        splitArea: {
            show: true
        }
    },
    visualMap: {
        min: 0,
        max: 10,
        // calculable: true,
        orient: 'horizontal',
        left: 'center',
        bottom: '15%'
    },
    series: [
        {
            name: 'Punch Card',
            type: 'heatmap',
            data: data,
            label: {
                show: true
            },
            emphasis: {
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};
const colorCodes = { 'unfilled': '#b22234' };
const pieChartConfig = {
    title: getTitle("Traffic Sources"),
    tooltip: {
        trigger: "item",
        formatter: "{b} : {c}",
    },
    grid: {
        left: '20%'
    },
    xAxis: {
        max: 'dataMax'
    },
    yAxis: {
        type: 'category',
        data: [],
        inverse: true,
        animationDuration: 300,
        animationDurationUpdate: 300,
        max: 9 // only the largest 10 bars will be displayed
    },
    legend: {
        show: true
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear',
    series: [
        {
            // name: "Traffic Sources",
            realtimeSort: true,
            type: "bar",
            radius: "55%",
            itemStyle: {
                borderRadius: [0, 5, 5, 0],
            },
            // center: ["50%", "60%"],
            data: [],
            // emphasis: {
            //     itemStyle: {
            //         shadowBlur: 10,
            //         shadowOffsetX: 0,
            //         shadowColor: "rgba(0, 0, 0, 0.5)",
            //     },
            // },
        },
    ],
}

const raceOptions = (lineItems) => ({
    xAxis: {
        max: 'dataMax',

    },
    grid: {
        left: '30%'
    },
    yAxis: {
        type: 'category',
        triggerEvent: true,
        data: [],
        inverse: true,
        // animationDuration: 300,
        // animationDurationUpdate: 300,
        max: 15, // only the largest 10 bars will be displayed
        axisLabel: {
            show: true,
            // fontSize: 14,
            formatter: function (value) {
                // console.log(lineItems);
                
                let foundInRetrieved = lineItems.value.find(e => e.value === value);
                return foundInRetrieved ? foundInRetrieved.label : value // '<a>' + value + '</a>'
            },
            // rich: {
            //   flag: {
            // fontSize: 14,
            // padding: 5
            //   }
            // }
        },
    },
    legend: {
        show: true
    },
    // animationDuration: 0,
    // animationDurationUpdate: 3000,
    // animationEasing: 'linear',
    // animationEasingUpdate: 'linear',
    title: getTitle('Lineitems'),
    series: [
        {
            // animationDelayUpdate: 1000,
            realtimeSort: true,
            // animationDelay: function (idx) {
            //     return idx * 100;
            // },
            itemStyle: {
                borderRadius: [0, 5, 5, 0],

                color: function (param) {
                    // console.log(param)
                    return colorCodes[param.name] || '#5470c6';
                }
            },
            type: 'bar',

            data: []
        }

    ],
    animationEasing: 'elasticOut',
    animationDelayUpdate: function (idx) {
        return idx * 5;
    }

});
const quintesseOptions = (quintesseOptions) => ({
    xAxis: {
        max: 'dataMax'
    },
    yAxis: {
        type: 'category',
        data: [],
        animationDuration: 300,
        animationDurationUpdate: 300,
        max: 25, // only the largest 10 bars will be displayed
        inverse: true,
        axisLabel: {
            show: true,
            formatter: function (value) {
                // console.log(quintesseOptions, value);
                
                let foundInRetrieved = quintesseOptions.value.find(e => e.name === value);
                return foundInRetrieved ? foundInRetrieved.displayName : value // '<a>' + value + '</a>'
            },

        },
    },
    legend: {
        show: true
    },
    animationDuration: 0,
    animationDurationUpdate: 3000,
    animationEasing: 'linear',
    animationEasingUpdate: 'linear',
    title: getTitle('Categories (Audience)'),
    series: [
        {
            label: {
                show: true,
                position: 'right',

                valueAnimation: true
            },
            itemStyle: {
                borderRadius: [0, 5, 5, 0],

            },
            realtimeSort: true,
            type: 'bar',
            data: []
        }

    ],

});


let gaugeOptions = {
    title: getTitle('Total unfilled %'),
    series: [
        {
            type: 'gauge',
            axisLine: {
                lineStyle: {
                    width: 30,
                    color: [
                        [0.1, '#67e0e3'],
                        [0.3, '#37a2da'],
                        [1, '#fd666d']
                    ]
                }
            },
            pointer: {
                itemStyle: {
                    color: 'inherit'
                }
            },
            axisTick: {
                distance: -30,
                length: 8,
                lineStyle: {
                    color: '#fff',
                    width: 2
                }
            },
            splitLine: {
                distance: -30,
                length: 30,
                lineStyle: {
                    color: '#fff',
                    width: 4
                }
            },
            axisLabel: {
                color: 'inherit',
                distance: 40,
                fontSize: 20
            },
            detail: {
                valueAnimation: true,
                formatter: '{value} %',
                color: 'inherit'
            },
            data: [
                {
                    value: 0
                }
            ]
        }
    ]
};

const dougnutOptions = {
    title: getTitle('Origin country'),
    tooltip: {
        trigger: 'item'
    },
    legend: {
        top: '5%',
        left: 'center',
        show: false
    },
    series: [
        {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
            },
            label: {
                show: false,
                position: 'center'
            },
            emphasis: {
                label: {
                    show: true,
                    fontSize: '40',
                    fontWeight: 'bold'
                }
            },
            labelLine: {
                show: false
            },
            data: [
                //   { value: 1048, name: 'Search Engine' },
                //   { value: 735, name: 'Direct' },
                //   { value: 580, name: 'Email' },
                //   { value: 484, name: 'Union Ads' },
                //   { value: 300, name: 'Video Ads' }
            ]
        }
    ]
}
module.exports = {
    dougnutOptions,
    pieChartConfig,
    areaChartOption,
    heatMapOptions,
    raceOptions,
    gaugeOptions,
    quintesseOptions
}