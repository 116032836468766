let dayjs = require("dayjs");
let utc = require("dayjs/plugin/utc");
let timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
let clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

// const updateRaces = ({ lineItem, site, addValueToRef, lineitemRef, siteRef }) => {
//     // let value = event.Data[indexToUse].ScalarValue;

//     let yAxisData = lineitemRef.value.yAxis.data;
//     let yAxisDataSite = siteRef.value.yAxis.data;
//     let foundItemLine = yAxisData.find((e) => e === lineItem);
//     let foundItemSite = yAxisDataSite.find((e) => e === site);
//     let dataObj = lineitemRef.value.series[0].data
//     let dataObjSite = siteRef.value.series[0].data

//     if (foundItemLine) {
//         let indexToIncrement = yAxisData.indexOf(lineItem);
//         dataObj[indexToIncrement] = dataObj[indexToIncrement] + 1;
//     } else {

//         lineitemRef.value.yAxis.data.push(lineItem);
//         dataObj.push(1);
//     }
//     if (foundItemSite) {
//         let indexToIncrement2 = yAxisDataSite.indexOf(site);
//         dataObjSite[indexToIncrement2] = dataObjSite[indexToIncrement2] + 1;
//     } else {
//         if (addValueToRef && site) {
//             addValueToRef.value.push(site)
//         }
//         siteRef.value.yAxis.data.push(site);
//         dataObjSite.push(1);
//     }
// };


const updateRace = ({ value, optionRef, addValueToRef }) => {
    // let value = event.Data[indexToUse].ScalarValue;

    let yAxisData = optionRef.value.yAxis.data;
    let foundItem = yAxisData.find((e) => e === value);
    let dataObj = optionRef.value.series[0].data

    if (foundItem) {
        let indexToIncrement = yAxisData.indexOf(value);
        dataObj[indexToIncrement] = dataObj[indexToIncrement] + 1;
    } else {
        if (addValueToRef && value) {
            addValueToRef.value.push(value)
        }
        optionRef.value.yAxis.data.push(value);
        dataObj.push(1);
    }
};


let initSite = (site) => ({
    name: site,
    value: 0,
    children: []
})
let initAdunit = (adunit) => ({
    name: adunit,
    value: 0,
})
const updateAdunitTree = ({ optionRef, adunitIndex, siteIndex, event }) => {
    let adunitCode = event.Data[adunitIndex].ScalarValue;
    let site = event.Data[siteIndex].ScalarValue;

    let dataArray = optionRef.value.series[0].data

    if (!dataArray.find(e => e.name === site)) {
        dataArray.push(initSite(site))
    }

    let siteObj = dataArray.find(e => e.name === site)
    if (!siteObj.children.find(child => child.name === adunitCode)) {
        siteObj.children.push(initAdunit(adunitCode))
    }

    let adunitObj = siteObj.children.find(child => child.name === adunitCode)

    siteObj.value = siteObj.value + 1
    adunitObj.value = adunitObj.value + 1

    // let child dataArray.find(e => e.name === site).chil

}

const updateCountry = ({ event, countryIndex, optionRef }) => {
    let value = event.Data[countryIndex].ScalarValue;

    let dataObj = optionRef.value.series[0].data
    let foundItem = dataObj.find((e) => e.name === value);

    if (foundItem) {
        foundItem.value = foundItem.value + 1;
    } else {
        dataObj.push({ value: 1, name: value });
    }
};

const clearRace = ({ optionRef }) => {
    optionRef.value.yAxis.data = []
    optionRef.value.series[0].data = []
}
const clearTree = ({ optionRef }) => {
    optionRef.value.series[0].data = []
}


const getIndexes = ({ namesToUse, ColumnInfo }) => namesToUse.map(name => {
    let wantedIndex = ColumnInfo.indexOf(
        ColumnInfo.find((e) => e.Name === name)
    );
    return wantedIndex;
});

const sortEvents = ({
    events,
    lineItemIndex,
    siteIndex,
    adunitIndex,
    treeRef,
    lineItemRef,
    // siteRef
}) => {
    let lineitemDataPoints = [];
    let lineItemAxisPoints = []
    // let siteDataPoints = [];
    // let siteAxisPoints = []
    let treeStructure = []
    events.forEach(event => {
        let adunitCode = event.Data[adunitIndex].ScalarValue;

        let lineItemId = event.Data[lineItemIndex].ScalarValue
        let site = event.Data[siteIndex].ScalarValue
        let foundLineItem = lineItemAxisPoints.find((e) => e === lineItemId);
        // LINEITEM
        if (foundLineItem) {
            let indexToIncrement = lineItemAxisPoints.indexOf(lineItemId);
            lineitemDataPoints[indexToIncrement] = lineitemDataPoints[indexToIncrement] + 1;
        } else {
            if (lineItemId) {
                lineItemAxisPoints.push(lineItemId)
                lineitemDataPoints.push(1)
            }
        }

        //SITE
        // let foundSite = siteAxisPoints.find((e) => e === site);

        // if (foundSite) {
        //     let siteIncrement = siteAxisPoints.indexOf(site);
        //     siteDataPoints[siteIncrement] = siteDataPoints[siteIncrement] + 1;
        // } else {
        //     if (site) {
        //         siteAxisPoints.push(site)
        //         siteDataPoints.push(1)
        //     }
        // }
        //Tree
        if (!treeStructure.find(e => e.name === site)) {
            treeStructure.push(initSite(site))
        }
        let siteObj = treeStructure.find(e => e.name === site)

        if (!siteObj.children.find(child => child.name === adunitCode)) {
            siteObj.children.push(initAdunit(adunitCode))
        }
        let adunitObj = siteObj.children.find(child => child.name === adunitCode)
        siteObj.value = siteObj.value + 1
        adunitObj.value = adunitObj.value + 1

    })

    lineItemRef.value.yAxis.data = lineItemAxisPoints;
    lineItemRef.value.series[0].data = lineitemDataPoints;
    // lineItemRef.value.series[lineItemRef.value.series.length] = { data: lineitemDataPoints, type: "bar" }

    // siteRef.value.yAxis.data = siteAxisPoints;
    // siteRef.value.series[0].data = siteDataPoints;
    // siteRef.value.series[siteRef.value.series.length] = { data: siteDataPoints, type: "bar" }
    treeRef.value.series[0].data = treeStructure

    return {
        // lineItemAxisPoints,
        // lineitemDataPoints,
        // siteDataPoints,
        // siteAxisPoints
    }
}


const updateRaceOfStrings = ({
    event,
    optionRef,
    indexes,
}) => {

    let yAxisData = optionRef.value.yAxis.data;
    indexes.forEach(indexOfAudienceData => {

        let value = event.Data[indexOfAudienceData].ScalarValue;
        if (!value) return
        let splittedValue = value.split(',')
        splittedValue.forEach(val => {
            let dataObj = optionRef.value.series[0].data
            let foundItem = yAxisData.find((e) => e === val);
            if (foundItem) {
                let indexToIncrement = yAxisData.indexOf(val);
                dataObj[indexToIncrement] = dataObj[indexToIncrement] + 1;
            } else {
                optionRef.value.yAxis.data.push(val);
                dataObj.push(1);
            }
        })
    })
};

const formatPrecission = 'YYYY-MM-DDTHH:mm:ssZ'
// const formatPrecission = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
const timestreamFormat = 'YYYY-MM-DD HH:mm:ss.SSS'
// 2022-04-29 07:33:49.644000000
const formatToLocalTime = (value) => dayjs(value).tz(clientTz).utc(true).format(formatPrecission);
const formatToAWSTimestream = datestring => dayjs(datestring).utc(false).format(timestreamFormat);
module.exports = {
    updateRace,
    updateAdunitTree,
    formatToLocalTime,
    updateRaceOfStrings,
    clearRace,
    sortEvents,
    updateCountry,
    getIndexes,
    clearTree,
    formatToAWSTimestream
}
