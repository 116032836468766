// const { faker } = require("@faker-js/faker");
// const countries = ["DK", "NO", "SE", "UNKNOWN", "US", "RU"];
// const sites = [
//     "autouncle.se",
//     "avisen.dk",
//     "baaam.se",
//     "bageglad.dk",
//     "bangladeshweekly.com",
//     "barometern.se",
//     "bbg_blogging",
//     "billedbladet.dk_passback",
//     "billedbladet.dk_spa",
//     "blogg.se",
//     "blt.se",
// ];


// const lineItems = [
//     12343455,
//     123434553,
//     334534535,
//     23234654,
//     365475646,
// ]
// const getRandomEvent = () => {
//     return {
//         id: faker.datatype.uuid(),
//         country: faker.random.arrayElement(countries),
//         site: faker.random.arrayElement(sites),
//         lineItemId: faker.random.arrayElement(lineItems),
//         creativeId: faker.random.arrayElement(lineItems),
//         // time:  Date.now()//faker.datatype.datetime()
//     };
// };



const groups = ({ arr, timeIndex }) => arr.reduce((groups, item) => ({
    ...groups,
    [item.Data[timeIndex].ScalarValue]: [...(groups[item.Data[timeIndex].ScalarValue] || []), item]
}), {});

// const randomCount = () => {
//     return faker.datatype.number({
//         min: 10,
//         max: 500,
//     });
// }


module.exports = {  groups }