<template>
  <v-chart
    class="chart"
    @click="emit"
    :loading="loading"
    :option="configObject"
  />
</template>
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";

import { BarChart } from "echarts/charts";

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { defineComponent } from "vue";

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  name: "BarChartComponent",
  components: { VChart },
  provide: {
    [THEME_KEY]: "dark",
  },
  setup() {
    let emit = (event) => {
      this.$emit("click", event);
    };

    return { emit };
  },
  props: {
    configObject: { type: Object },
    loading: { type: Boolean, default: false },
  },
});
</script>

<style scoped></style>
