function getLevelOption() {
    return [
        {
            itemStyle: {
                borderColor: '#777',
                borderWidth: 0,
                gapWidth: 1
            },
            upperLabel: {
                show: false
            }
        },
        {
            itemStyle: {
                borderColor: '#555',
                borderWidth: 5,
                gapWidth: 1
            },
            emphasis: {
                itemStyle: {
                    borderColor: '#ddd'
                }
            }
        },
        {
            colorSaturation: [0.35, 0.5],
            itemStyle: {
                borderWidth: 5,
                gapWidth: 1,
                borderColorSaturation: 0.6
            }
        }
    ];
}
let treeOptions = {
    series: [
        {
            type: 'treemap',
            visibleMin: 30,
            label: {
                // formatter: function (params) {
                //     console.log('params');
                    
                // },
                // position: 'insideTopLeft',
                show: true,
                formatter: '{b} - {c}'
            },
            upperLabel: {
                show: true,
                height: 30
            },
            itemStyle: {
                borderColor: '#fff'
            },
            levels: getLevelOption(),
            data: [
                // {
                //     name: 'nodeA',
                //     value: 10,
                //     children: [
                //         {
                //             name: 'nodeAa',
                //             value: 4
                //         },
                //         {
                //             name: 'nodeAb',
                //             value: 6
                //         }
                //     ]
                // },
                // {
                //     name: 'nodeB',
                //     value: 20,
                //     children: [
                //         {
                //             name: 'nodeBa',
                //             value: 20,
                //             children: [
                //                 {
                //                     name: 'nodeBa1',
                //                     value: 20
                //                 }
                //             ]
                //         }
                //     ]
                // }
            ]
        }
    ]
};



module.exports = {
    treeOptions
}