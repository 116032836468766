<template>
  <div color="background">
    <div class="row my-2 mx-2">
      <div class="flex md4 xs12">
        <div class="mx-2 md6">
          <h3 class="display-3 py-4">Filter sites</h3>

          <va-select
            label="Select site(s)"
            :options="allSites"
            @update:model-value="updateCharts"
            v-model="filteredSitesChoosen"
            multiple
            bordered
          />
        </div>
        <div class="mx-2 md6">
          <h3 class="display-3 py-4">Filter Lineitems</h3>

          <n-select
            v-model:value="filteredLineItemsChoosen"
            :options="lineitems"
            filterable
            placeholder="Select lineItems(s)"
            multiple
            @update:value="updateCharts"
          />
        </div>
        <div class="my-2 md6">
          <va-button @click="changeCurrentFlow" type="primary">
            {{ started ? "Stop" : "Start" }}
          </va-button>
        </div>
      </div>

      <div class="flex md6 xs12">
        <div class="item">
          <BarChartComponent :configObject="areaChartOptions" />
        </div>
      </div>
      <div class="flex xs12 md2">
        <div class="item">
          <GaugeChartComponent class="chart" :configObject="gaugeOption" />
        </div>
      </div>
    </div>

    <div class="row my-2 mx-2">
      <div class="flex xs12">
        <div class="item">
          <TreeChartComponent class="chart2" :configObject="treeOptions" />
          <RaceChartComponent
            @click="redirect"
            :configObject="lineItemsConfigs"
          />
        </div>
      </div>
    </div>
    <div class="row my-2 mx-2"></div>

    <div class="row mx-2 my-4">
      <div class="flex md12">
        <h3 class="display-3 py-4">Filter audience</h3>

        <va-select
          @update:model-value="updateCharts"
          label="Select audience"
          :options="audienceOptions"
          bordered
          v-model="selectedOptions"
          multiple
        />
      </div>
    </div>
    <div class="row my-2 mx-2">
      <div class="flex xs12">
        <div class="item">
          <RaceChartComponent class="chart2" :configObject="quintesseConfig" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chartOptions from "../configs/chartConfig";
import tree from "../configs/treeOptions";
import { NSelect } from "naive-ui";

import computingFunction from "../computeFunctions/computeCharts";
import BarChartComponent from "./chartTypes/BarChart.vue";
import RaceChartComponent from "./chartTypes/RaceChart.vue";
import TreeChartComponent from "./chartTypes/TreeChart.vue";
import GaugeChartComponent from "./chartTypes/GaugeChart.vue";

import { ref, defineComponent, computed, nextTick } from "vue";
import { useLineItemStore } from "../stores/lineitems";
import { userSeedStore } from "../stores/seed";
import { userEventsStore } from "../stores/events";
import { useAudienceStore } from "../stores/audiences";

export default defineComponent({
  name: "ChartsComponent",
  components: {
    NSelect,
    BarChartComponent,
    RaceChartComponent,
    TreeChartComponent,
    GaugeChartComponent,
  },

  async setup() {
    const lineitemStore = useLineItemStore();
    const sitesStore = userSeedStore();
    const eventStore = userEventsStore();
    const audienceStore = useAudienceStore();
    window.onfocus = function () {
      processAllEvents();
    };

    const areaChartOptions = ref(chartOptions.areaChartOption);
    const treeOptions = ref(tree.treeOptions);
    const lineitems = computed(() => lineitemStore.lineItems);
    const keyValues = computed(() => sitesStore.keyValues);

    const lineItemsConfigs = ref(chartOptions.raceOptions(lineitems));
    const quintesseConfig = ref(chartOptions.quintesseOptions(keyValues));
    const gaugeOption = ref(chartOptions.gaugeOptions);

    let unfilled = ref(0);
    let total = ref(0);
    let secondDrawerId = ref(0);
    let filled = ref(0);
    let started = ref(false);

    const selectedOptions = ref(audienceStore.audienceOptions);
    const filteredSitesChoosen = ref([]);
    const secondCountEvents = computed(() => eventStore.secondCountEvents);
    const filteredLineItemsChoosen = ref([]);
    const unfilledPercent = computed(() =>
      parseFloat((unfilled.value / total.value) * 100).toFixed(2)
    );
    await sitesStore.getSeedData();

    const updateGauge = ({ event }) => {
      let lineItem = event.Data[eventStore.lineItemIndex].ScalarValue;
      total.value = total.value + 1;
      if (lineItem === "unfilled") {
        unfilled.value = unfilled.value + 1;
      } else {
        filled.value = filled.value + 1;
      }
      gaugeOption.value.series[0].data[0].value = unfilledPercent;
    };
    const mainFilter = ({ event }) => {
      let lineItem = event.Data[eventStore.lineItemIndex].ScalarValue;
      let lineitemIdsToGet = lineitemStore.lineitemsToGet.map((e) => e.id);
      if (
        !lineitemStore.lineItems.find((e) => e.value === lineItem) &&
        !["unfilled", "0", "null"].includes(lineItem) &&
        !lineitemIdsToGet.includes(lineItem)
      ) {
        lineitemStore.addLineItemToGet(lineItem);
      }

      return event;
    };
    const clearCharts = () => {
      unfilled.value = 0;
      // secondCountEvents.value = [];
      total.value = 0;
      filled.value = 0;
      computingFunction.clearRace({ optionRef: areaChartOptions });
      computingFunction.clearRace({ optionRef: quintesseConfig });
      // computingFunction.clearRace({ optionRef: SiteOption });
      computingFunction.clearRace({ optionRef: lineItemsConfigs });
      computingFunction.clearTree({ optionRef: treeOptions });
    };
    const updateEventsArrayFiltered = ({
      eventArray,
      updateGaugeValue = false,
      addtoValue = false,
    }) => {
      let stringIndexes = computingFunction.getIndexes({
        namesToUse: selectedOptions.value,
        ColumnInfo: eventStore.ColumnInfo,
      });
      let eventsToUse = eventArray.filter((event) => mainFilter({ event }));

      eventsToUse.forEach((event) => {
        if (addtoValue) {
          let site = event.Data[eventStore.siteIndex].ScalarValue;
          sitesStore.addSite(site);
        }
        computingFunction.updateRace({
          value: event.Data[eventStore.lineItemIndex].ScalarValue,
          optionRef: lineItemsConfigs,
        });

        // THIS IS HEAVY
        computingFunction.updateRaceOfStrings({
          event,
          optionRef: quintesseConfig,
          indexes: stringIndexes,
        });
        computingFunction.updateAdunitTree({
          optionRef: treeOptions,
          adunitIndex: eventStore.adunitIndex,
          siteIndex: eventStore.siteIndex,
          event,
        });

        if (updateGaugeValue) {
          updateGauge({ event });
        }
      });
    };

    const updateCharts = async () => {
      await nextTick();
      clearCharts();
    };

    const partialQueueEmpty = () => {
      let firstEventGroup = secondCountEvents.value.shift();
      areaChartOptions.value.series[0].data.push(firstEventGroup);
      let eventArray = eventStore.mainDrawingEvents.shift();

      if (eventArray) {
        updateEventsArrayFiltered({
          eventArray,
          updateGaugeValue: true,
          addtoValue: true,
        });
      }
    };
    const secondDrawer = () => {
      secondDrawerId = setTimeout(function () {
        partialQueueEmpty();
        secondDrawer();
      }, 1000);
    };
    const processAllEvents = () => {
      secondCountEvents.value.forEach(() => {
        partialQueueEmpty();
      });
    };

    const changeCurrentFlow = () => {
      if (started.value) {
        eventStore.clearAllEventsAndStop();
        updateCharts();
        clearTimeout(secondDrawerId);
      } else {
        
        eventStore.getEvents({
          timeAgo: "2m",
          sitesFilter: filteredSitesChoosen.value,
          lineItemFilter: filteredLineItemsChoosen.value,
        });
        lineitemStore.startLineItemLoop();
        secondDrawer();
      }
      started.value = !started.value;
    };

    return {
      updateCharts,
      areaChartOptions,
      lineItemsConfigs,
      gaugeOption,
      changeCurrentFlow,
      unfilled,
      treeOptions,
      audienceOptions: audienceStore.audienceOptions,
      selectedOptions,
      filled,
      filteredSitesChoosen,
      filteredLineItemsChoosen,
      lineitems: lineitemStore.lineItems,
      allSites: sitesStore.sites,
      total,
      unfilledPercent,
      started,
      quintesseConfig,
    };
  },
  methods: {
    redirect({ targetType, value }) {
      if (targetType === "axisLabel" && value && value !== "unfilled") {
        window
          .open(
            `https://admanager.google.com/5706918#delivery/line_item/detail/line_item_id=${value}&line_item=true&li_tab=settings`,
            "_blank"
          )
          .focus();
      }
    },
  },
});
</script>

<style>
.chart {
  height: 400px;
}
.chart2 {
  height: 800px;
}
</style>
