import Vuex from 'vuex'
import {
    set,
    keys,
    getAll
} from "../helpers/idb";

export const store = new Vuex.Store({
    state: {
        lineItems: [],
        lineitemsToGet: []
    },
    mutations: {
        insertLineItem(state, lineitem) {
            if (!state.lineItems.find(e => e.value === lineitem.value)) {
                set(lineitem.value, lineitem.label)
                state.lineItems.push(lineitem)
            }
        },
        addLineItemToGet(state, lineitem) {
            state.lineitemsToGet.push(lineitem)
        },
        removeTranslatedItems(state, { newlineitems }) {
            state.lineitemsToGet = state.lineitemsToGet.filter(
                (id) => !newlineitems.GAMIds.map((e) => e.id).includes(id)
            );
        }

    },
    actions: {
        async POPULATE_FROM_CACHE({ state }) {
            console.time('restoreCache')
            let [keyss, values] = await Promise.all([keys(), getAll()])
            state.lineItems = keyss.map((key, index) => ({ value: key, label: values[index] }))
            console.timeEnd('restoreCache')
        },
        // START_LINEITEM_LOOP({ state }) {

        // }
    },
    getters: {
        lineItems: state => state.lineItems,
        lIMissingTranslations: state => state.lineitemsToGet
    }
})


