export const APISettings = {
    token: '',
    headers: new Headers({
        'Accept': 'application/json',
        "Content-Type": 'application/json',
        "x-api-key": process.env.NODE_ENV === 'production'
            ? 'OB1YwImbkwEnWtzmh4lT2LXGTjGq8EI5XUJChin9'
            : 'd41d8cd98f00b204e9800998ecf8427e',
    }),
    baseURL: process.env.NODE_ENV === 'production'
        ? 'https://6bmjr4s7l1.execute-api.eu-west-1.amazonaws.com/dev'
        : 'http://localhost:3000/dev',
}